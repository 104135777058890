// If the browser is internet explorer 10
if (navigator.appVersion.indexOf("MSIE 10") !== -1) {
    document.documentElement.className+=' br-ie10';
}

/* Modernizr 2.8.3 (Custom Build) | MIT & BSD
 * Build: http://modernizr.com/download/#-flexbox-mq-cssclasses-teststyles-testprop-prefixes-css_remunit-cssclassprefix:mn!
 */
;



window.Modernizr = (function( window, document, undefined ) {

    var version = '2.8.3',

    Modernizr = {},

    enableClasses = true,

    docElement = document.documentElement,

    mod = 'modernizr',
    modElem = document.createElement(mod),
    mStyle = modElem.style,

    inputElem  ,


    toString = {}.toString,

    prefixes = ' -webkit- -moz- -o- -ms- '.split(' '),



    omPrefixes = 'Webkit Moz O ms',

    cssomPrefixes = omPrefixes.split(' '),

    domPrefixes = omPrefixes.toLowerCase().split(' '),


    tests = {},
    inputs = {},
    attrs = {},

    classes = [],

    slice = classes.slice,

    featureName,


    injectElementWithStyles = function( rule, callback, nodes, testnames ) {

      var style, ret, node, docOverflow,
          div = document.createElement('div'),
                body = document.body,
                fakeBody = body || document.createElement('body');

      if ( parseInt(nodes, 10) ) {
                      while ( nodes-- ) {
              node = document.createElement('div');
              node.id = testnames ? testnames[nodes] : mod + (nodes + 1);
              div.appendChild(node);
          }
      }

                style = ['&#173;','<style id="s', mod, '">', rule, '</style>'].join('');
      div.id = mod;
          (body ? div : fakeBody).innerHTML += style;
      fakeBody.appendChild(div);
      if ( !body ) {
                fakeBody.style.background = '';
                fakeBody.style.overflow = 'hidden';
          docOverflow = docElement.style.overflow;
          docElement.style.overflow = 'hidden';
          docElement.appendChild(fakeBody);
      }

      ret = callback(div, rule);
        if ( !body ) {
          fakeBody.parentNode.removeChild(fakeBody);
          docElement.style.overflow = docOverflow;
      } else {
          div.parentNode.removeChild(div);
      }

      return !!ret;

    },

    testMediaQuery = function( mq ) {

      var matchMedia = window.matchMedia || window.msMatchMedia;
      if ( matchMedia ) {
        return matchMedia(mq) && matchMedia(mq).matches || false;
      }

      var bool;

      injectElementWithStyles('@media ' + mq + ' { #' + mod + ' { position: absolute; } }', function( node ) {
        bool = (window.getComputedStyle ?
                  getComputedStyle(node, null) :
                  node.currentStyle)['position'] == 'absolute';
      });

      return bool;

     },
    _hasOwnProperty = ({}).hasOwnProperty, hasOwnProp;

    if ( !is(_hasOwnProperty, 'undefined') && !is(_hasOwnProperty.call, 'undefined') ) {
      hasOwnProp = function (object, property) {
        return _hasOwnProperty.call(object, property);
      };
    }
    else {
      hasOwnProp = function (object, property) {
        return ((property in object) && is(object.constructor.prototype[property], 'undefined'));
      };
    }


    if (!Function.prototype.bind) {
      Function.prototype.bind = function bind(that) {

        var target = this;

        if (typeof target != "function") {
            throw new TypeError();
        }

        var args = slice.call(arguments, 1),
            bound = function () {

            if (this instanceof bound) {

              var F = function(){};
              F.prototype = target.prototype;
              var self = new F();

              var result = target.apply(
                  self,
                  args.concat(slice.call(arguments))
              );
              if (Object(result) === result) {
                  return result;
              }
              return self;

            } else {

              return target.apply(
                  that,
                  args.concat(slice.call(arguments))
              );

            }

        };

        return bound;
      };
    }

    function setCss( str ) {
        mStyle.cssText = str;
    }

    function setCssAll( str1, str2 ) {
        return setCss(prefixes.join(str1 + ';') + ( str2 || '' ));
    }

    function is( obj, type ) {
        return typeof obj === type;
    }

    function contains( str, substr ) {
        return !!~('' + str).indexOf(substr);
    }

    function testProps( props, prefixed ) {
        for ( var i in props ) {
            var prop = props[i];
            if ( !contains(prop, "-") && mStyle[prop] !== undefined ) {
                return prefixed == 'pfx' ? prop : true;
            }
        }
        return false;
    }

    function testDOMProps( props, obj, elem ) {
        for ( var i in props ) {
            var item = obj[props[i]];
            if ( item !== undefined) {

                            if (elem === false) return props[i];

                            if (is(item, 'function')){
                                return item.bind(elem || obj);
                }

                            return item;
            }
        }
        return false;
    }

    function testPropsAll( prop, prefixed, elem ) {

        var ucProp  = prop.charAt(0).toUpperCase() + prop.slice(1),
            props   = (prop + ' ' + cssomPrefixes.join(ucProp + ' ') + ucProp).split(' ');

            if(is(prefixed, "string") || is(prefixed, "undefined")) {
          return testProps(props, prefixed);

            } else {
          props = (prop + ' ' + (domPrefixes).join(ucProp + ' ') + ucProp).split(' ');
          return testDOMProps(props, prefixed, elem);
        }
    }    tests['flexbox'] = function() {
      return testPropsAll('flexWrap');
    };
    for ( var feature in tests ) {
        if ( hasOwnProp(tests, feature) ) {
                                    featureName  = feature.toLowerCase();
            Modernizr[featureName] = tests[feature]();

            classes.push((Modernizr[featureName] ? '' : 'no-') + featureName);
        }
    }



     Modernizr.addTest = function ( feature, test ) {
       if ( typeof feature == 'object' ) {
         for ( var key in feature ) {
           if ( hasOwnProp( feature, key ) ) {
             Modernizr.addTest( key, feature[ key ] );
           }
         }
       } else {

         feature = feature.toLowerCase();

         if ( Modernizr[feature] !== undefined ) {
                                              return Modernizr;
         }

         test = typeof test == 'function' ? test() : test;

         if (typeof enableClasses !== "undefined" && enableClasses) {
           docElement.className+=" mn-" + (test ? '' : 'no-') + feature;
         }
         Modernizr[feature] = test;

       }

       return Modernizr;
     };


    setCss('');
    modElem = inputElem = null;


    Modernizr._version      = version;

    Modernizr._prefixes     = prefixes;
    Modernizr._domPrefixes  = domPrefixes;
    Modernizr._cssomPrefixes  = cssomPrefixes;

    Modernizr.mq            = testMediaQuery;


    Modernizr.testProp      = function(prop){
        return testProps([prop]);
    };

    Modernizr.testAllProps  = testPropsAll;


    Modernizr.testStyles    = injectElementWithStyles;    docElement.className = docElement.className.replace(/(^|\s)no-js(\s|$)/, '$1$2') +

                                                    (enableClasses ? " mn-js mn-"+classes.join(" mn-") : '');

    return Modernizr;

})(this, this.document);

// test by github.com/nsfmc

// "The 'rem' unit ('root em') is relative to the computed
// value of the 'font-size' value of the root element."
// http://www.w3.org/TR/css3-values/#relative0
// you can test by checking if the prop was ditched

// http://snook.ca/archives/html_and_css/font-size-with-rem

Modernizr.addTest('cssremunit', function(){

  var div = document.createElement('div');
  try {
    div.style.fontSize = '3rem';
  } catch(er){}
  return (/rem/).test(div.style.fontSize);

});
;

$(function() {
    setTimeout(function () {
        $('#switcher').off('swipeLeft swipeRight');
    }, 100);
});

var Application,
  bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; },
  indexOf = [].indexOf || function(item) { for (var i = 0, l = this.length; i < l; i++) { if (i in this && this[i] === item) return i; } return -1; };

Application = (function() {
  function Application() {
    this.register = bind(this.register, this);
    this.run = bind(this.run, this);
    this.modules = [];
  }

  Application.prototype.run = function() {
    var i, len, module, ref, results, route;
    route = $('body').data('route');
    ref = this.modules;
    results = [];
    for (i = 0, len = ref.length; i < len; i++) {
      module = ref[i];
      if (!module.routes || indexOf.call(module.routes, route) >= 0) {
        results.push(module.run());
      } else {
        results.push(void 0);
      }
    }
    return results;
  };

  Application.prototype.register = function(module) {
    return this.modules.push(module);
  };

  return Application;

})();

var CartModule,
  bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; },
  indexOf = [].indexOf || function(item) { for (var i = 0, l = this.length; i < l; i++) { if (i in this && this[i] === item) return i; } return -1; };

CartModule = (function() {
  function CartModule() {
    this.set_products = bind(this.set_products, this);
    this.get_products = bind(this.get_products, this);
    this.on_product_change = bind(this.on_product_change, this);
    this.on_star_click = bind(this.on_star_click, this);
    this.update_cart = bind(this.update_cart, this);
    this.update_stars = bind(this.update_stars, this);
    this.update_menu = bind(this.update_menu, this);
    this.update = bind(this.update, this);
    this.run = bind(this.run, this);
  }

  CartModule.prototype.run = function() {
    this.stars = $('[data-star]');
    this.stars.click(this.on_star_click);
    this.stars.bind('product_change', this.on_product_change);
    return this.update();
  };

  CartModule.prototype.update = function() {
    this.update_menu();
    this.update_stars();
    return this.update_cart();
  };

  CartModule.prototype.update_menu = function() {
    var products, target;
    products = this.get_products();
    target = $('.mainnav-stars');
    target.hide();
    target.html();
    if (products.length) {
      target.find('.mainnav-stars-count').html(products.length);
      return target.show();
    }
  };

  CartModule.prototype.update_stars = function() {
    return this.stars.map((function(_this) {
      return function(index, element) {
        var product, products;
        element = $(element);
        product = element.data('star');
        products = _this.get_products();
        if (indexOf.call(products, product) >= 0) {
          return element.removeClass('fa-star-o').addClass('fa-star');
        } else {
          return element.removeClass('fa-star').addClass('fa-star-o');
        }
      };
    })(this));
  };

  CartModule.prototype.update_cart = function() {
    var elements, products;
    products = this.get_products();
    elements = $('body[data-route="select/catalog/cart"] .product');
    elements.map((function(_this) {
      return function(index, element) {
        var product;
        element = $(element);
        product = element.find('[data-star]').data('star');
        element.hide();
        if (indexOf.call(products, product) >= 0) {
          return element.show();
        }
      };
    })(this));
    return elements.filter(':visible:first').css('border-top', 'none');
  };

  CartModule.prototype.on_star_click = function(event) {
    var element, product, products;
    element = $(event.currentTarget);
    product = element.data('star');
    products = this.get_products();
    if (indexOf.call(products, product) >= 0) {
      products = _.without(products, product);
    } else {
      products.push(product);
    }
    this.set_products(products);
    return this.update();
  };

  CartModule.prototype.on_product_change = function(event) {
    return this.update();
  };

  CartModule.prototype.get_products = function() {
    var products;
    products = [];
    if (localStorage.cart) {
      products = JSON.parse(localStorage.cart);
    }
    return products;
  };

  CartModule.prototype.set_products = function(products) {
    return localStorage.cart = JSON.stringify(products);
  };

  return CartModule;

})();

var FlipModule,
  bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; };

FlipModule = (function() {
  function FlipModule() {
    this.set = bind(this.set, this);
    this.get = bind(this.get, this);
    this.on_link_click = bind(this.on_link_click, this);
    this.update = bind(this.update, this);
    this.run = bind(this.run, this);
  }

  FlipModule.prototype.run = function() {
    this.links = $('a[data-flip]');
    this.images = $('img[data-flip]');
    this.links.click(this.on_link_click);
    return this.update();
  };

  FlipModule.prototype.update = function(click) {
    var animation, flip;
    flip = this.get();
    if (click) {
      animation = 'uk-animation-fade';
      this.links.addClass(animation);
      this.images.addClass(animation);
      setTimeout(((function(_this) {
        return function() {
          _this.links.removeClass(animation);
          return _this.images.removeClass(animation);
        };
      })(this)), 1000);
    }
    if (flip) {
      this.links.html('левозаходные');
      return this.images.addClass('ut-flip');
    } else {
      this.links.html('правозаходные');
      return this.images.removeClass('ut-flip');
    }
  };

  FlipModule.prototype.on_link_click = function(event) {
    event.preventDefault();
    this.set(!this.get());
    return this.update(true);
  };

  FlipModule.prototype.get = function() {
    return JSON.parse(localStorage.flip || 'false');
  };

  FlipModule.prototype.set = function(flip) {
    return localStorage.flip = JSON.stringify(flip);
  };

  return FlipModule;

})();

jQuery((function(_this) {
  return function() {
    var app;
    app = new Application();
    app.register(new CartModule());
    app.register(new CategoryModule());
    app.register(new FlipModule());
    app.register(new HeroModule());
    app.register(new ScrollModule());
    return app.run();
  };
})(this));

var ScrollModule,
  bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; };

ScrollModule = (function() {
  function ScrollModule() {
    this.scroll = bind(this.scroll, this);
    this.on_click = bind(this.on_click, this);
    this.run = bind(this.run, this);
  }

  ScrollModule.prototype.run = function() {
    var hash;
    hash = $(location).attr('hash');
    if (hash) {
      this.scroll(hash);
    }
    return $('a[href^="#"]').click(this.on_click);
  };

  ScrollModule.prototype.on_click = function(event) {
    var element, hash;
    event.preventDefault();
    element = event.currentTarget;
    hash = $(element).attr('href').replace('/', '');
    return this.scroll(hash);
  };

  ScrollModule.prototype.scroll = function(hash) {
    var element, offset;
    element = $(hash);
    if (element.length) {
      if (element.is(':hidden')) {
        element.parents('.uk-switcher').children().removeClass('uk-active');
        element.parents('.uk-switcher>li').addClass('uk-active');
      }
      offset = $('.header').height() + 20;
      return UIkit.Utils.scrollToElement(element, {
        offset: offset
      });
    }
  };

  return ScrollModule;

})();

jQuery(function () {
    $('[data-callback-trigger]').click(function (event) {
        event.preventDefault();
        Comagic.openSitePhonePanel();
    })
})

jQuery(function () {

    var content = $('.delivery-content');
    var link = $('.delivery-close-link');

    // Initial sync
    sync();

    // Togle on click
    link.click(function (event) {
        var visible = JSON.parse(localStorage.delivery || 'true');
        localStorage.delivery = JSON.stringify(!visible)
        event.preventDefault();
        sync();
    });

    // Sync content status
    function sync(status) {
        var visible = JSON.parse(localStorage.delivery || 'true');
        if (visible) {
            content.show();
        } else {
            content.hide();
        }
    }

});

var CategoryModule,
  bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; };

CategoryModule = (function() {
  function CategoryModule() {
    this.on_item_click = bind(this.on_item_click, this);
    this.run = bind(this.run, this);
  }

  CategoryModule.prototype.run = function() {
    return $('.category-item-link').click(this.on_item_click);
  };

  CategoryModule.prototype.on_item_click = function(event) {
    var element, target;
    event.preventDefault();
    element = $(event.currentTarget);
    target = element.parents('.category');
    return target.find('.category-heading, .category-image, .category-table').fadeOut('fast', (function(_this) {
      return function() {
        var active;
        target.find('.category-heading-link').attr('href', element.data('link'));
        target.find('.category-heading-name').html(element.data('name'));
        target.find('.category-image').attr('src', element.data('image'));
        target.find('.category-star').data('stair', element.data('slug'));
        target.find('.category-star').trigger('stair_change');
        target.find('.category-price-value').html(element.data('price'));
        target.find('.category-price-currency').hide();
        if (element.data('presence')) {
          target.find('.category-price-currency').show();
        }
        target.find('.category-notice').hide();
        if (element.data('discount')) {
          target.find('.category-notice').show();
        }
        target.find('.category-turn').html(element.data('turn'));
        target.find('.category-height').html(element.data('height'));
        target.find('.category-window').html(element.data('window'));
        target.find('.category-wood').html(element.data('wood'));
        active = 'category-item--active';
        element.parent().siblings().removeClass(active);
        element.parent().addClass(active);
        return target.find('.category-heading, .category-image, .category-table').fadeIn('slow');
      };
    })(this));
  };

  return CategoryModule;

})();

var HeroModule,
  bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; };

HeroModule = (function() {
  function HeroModule() {
    this.on_interval = bind(this.on_interval, this);
    this.on_thumb_click = bind(this.on_thumb_click, this);
    this.run = bind(this.run, this);
  }

  HeroModule.prototype.run = function() {
    this.thumbs = $('.hero-thumb');
    if (this.thumbs.length) {
      this.interval = setInterval(this.on_interval, 5000);
      return this.thumbs.click(this.on_thumb_click);
    }
  };

  HeroModule.prototype.on_thumb_click = function(event, auto) {
    var active, element, target;
    if (auto == null) {
      auto = false;
    }
    if (!auto) {
      clearInterval(this.interval);
    }
    event.preventDefault;
    active = 'hero-thumb--active';
    element = $(event.currentTarget);
    element.siblings().removeClass(active);
    element.addClass(active);
    target = $('.hero-figure');
    target.stop();
    return target.fadeOut('fast', (function(_this) {
      return function() {
        target.find('.hero-image').attr('src', element.data('image'));
        target.find('.hero-link').attr('href', element.data('link'));
        target.find('.hero-link-name').html(element.data('name'));
        target.find('.hero-price-value').html(element.data('price'));
        return target.fadeIn(1000);
      };
    })(this));
  };

  HeroModule.prototype.on_interval = function() {
    var current, next;
    current = $('.hero-thumb--active');
    next = current.next();
    if (!next.length) {
      next = current.siblings().first();
    }
    return next.trigger('click', [true]);
  };

  return HeroModule;

})();
